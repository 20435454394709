import React from "react"
import Image  from "../components/image"
import Layout from "../components/layout"
// import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
      <div >
          <Image alt="home automation" filename="404.png"  />
      </div>
  </Layout>
)

export default NotFoundPage
